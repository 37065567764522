import { useLocalization } from '@akinon/next/hooks';
import { Icon, Link } from '@theme/components';
import { ROUTES } from '@theme/routes';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Results from './results';

export default function Search({
  setSearchOpen,
  data,
  searchText,
  setSearchText,
  doSearch
}) {
  const { t } = useLocalization();
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    const history = JSON.parse(localStorage.getItem('searchHistory')) || [];

    const uniqueHistory = Array.from(new Set(history));
    setSearchHistory(uniqueHistory);
  }, []);

  function handleSearch(text: string) {
    setSearchText(text);
    doSearch(text);
    setSearchOpen(false);
  }

  return (
    <div
      id="header-search-modal"
      className={twMerge(
        'flex w-full flex-col gap-4 rounded-xl bg-gray-25 px-[38px] py-[30px] drop-shadow-md',
        'absolute inset-x-0 top-0 z-40 h-[100vh] lg:top-[52px] lg:h-[calc(100vh-100%-72px)]',
        'lg:top-[3.75rem] lg:h-auto lg:border lg:border-gray-380 lg:p-4 xl:w-[38.25rem]',
        'max-lg:overflow-y-auto',
        'pb-[1.875rem] pt-[160px] lg:pt-0'
      )}
    >

      {searchHistory.length > 0 && (
        <div className="lg:mt-0 flex flex-col items-start gap-[0.875rem] border-b border-gray-300 py-3">
          <h3 className="text-sm font-semibold text-black">
            {t('common.search.recent')}
          </h3>
          {searchHistory?.slice(0, 3)?.map((item, i) => (
            <div key={i} className="flex items-center justify-start gap-2">
              <Icon name="clock" size={16} />
              <Link
                onClick={() => handleSearch(item)}
                href=""
                className="cursor-pointer text-xs font-semibold text-black"
              >
                {item}
              </Link>
            </div>
          ))}
        </div>
      )}

      <Results searchText={searchText} />

      <div className="flex flex-col items-start gap-[0.875rem] py-4">
        <h3 className="text-sm font-semibold text-black">
          {t('common.search.trending')}
        </h3>
        <div className="flex flex-wrap items-center justify-start gap-3 mb-12">
          {data?.map((el, i) => (
            <Link
              key={i}
              onClick={() => setSearchOpen(false)}
              href={el?.value?.trend_item_link || ''}
              className={twMerge(
                'rounded-full border border-black px-3 py-1.5 text-sm font-normal text-black',
                'hover:border-primary hover:bg-primary hover:bg-opacity-10 hover:text-primary'
              )}
            >
              {el?.value?.trend_item}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
