'use client';

import { Link, Icon } from '@theme/components';
import { useSession } from 'next-auth/react';
import { ROUTES } from '@theme/routes';
import clsx from 'clsx';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { useLocalization } from '@akinon/next/hooks';
import { useGetProfileInfoQuery } from '@akinon/next/data/client/account';

interface UserMenuProps {
  isMobile: boolean;
}

export const UserMenu = (props: UserMenuProps) => {
  const { isMobile } = props;
  const { data: session, status } = useSession();
  const { t } = useLocalization();
  const dispatch = useAppDispatch();

  const { data: profileData, isLoading } = useGetProfileInfoQuery(undefined, {
    skip: status !== 'authenticated'
  });

  const updateActiveTab = (value) => {
    localStorage.setItem('activeTab', value ? 'true' : 'false');

    // Custom event dispatch
    const event = new Event('activeTabChange');
    window.dispatchEvent(event); // Değişikliği duyur
  };

  const MenuItems = [
    {
      url: ROUTES.AUTH + '?tab=login',
      label: t('common.header.login'),
      dataTestId: `header-login${isMobile ? '-mobile' : ''}`,
      icon: 'profile'
    }
  ];

  return (
    <ul
      className={clsx(
        'items-center divide-x divide-black',
        isMobile ? '' : 'hidden sm:flex'
      )}
      id="user-menu"
    >
      {status === 'authenticated' ? (
        <li>
          <Link
            href={ROUTES.ACCOUNT}
            data-testid={`header-user${isMobile ? '-mobile' : ''}`}
            className="flex items-center space-x-2.5"
            onClick={() => dispatch(closeMobileMenu())}
          >
            <span className="block h-10 w-10 p-1.5 lg:h-11 lg:w-11 lg:p-1">
              <span className="flex-center h-7 w-7 rounded-full bg-[#F8E10B] font-jost text-base font-medium uppercase leading-5 text-black lg:h-9 lg:w-9 lg:text-[22px] lg:leading-8">
                {isLoading ? (
                  '...'
                ) : (
                  <>
                    {profileData?.first_name?.charAt(0)}
                    {profileData?.last_name &&
                    profileData?.last_name.includes('.')
                      ? ''
                      : profileData?.last_name.charAt(0)}
                  </>
                )}
              </span>
            </span>
          </Link>
        </li>
      ) : (
        MenuItems.map((link, index) => (
          <li key={index} className={clsx('flex items-center uppercase')}>
            <Link
              href={link.url}
              passHref={true}
              data-testid={link.dataTestId}
              className="flex size-10 items-center justify-center rounded-md bg-[#fafbfc] lg:size-11 lg:bg-transparent"
              onClick={() => updateActiveTab(true)}
            >
              <Icon name={link.icon} size={24} />
            </Link>
          </li>
        ))
      )}
    </ul>
  );
};
