'use client';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Link, LoaderSpinner } from '@theme/components';
import { Icon } from '@akinon/next/components';
import { useLocalization, useOnClickOutside } from '@akinon/next/hooks';
import useUserLocation from '@theme/hooks/use-user-location';
import { useSession } from 'next-auth/react';
import { NewAddressModal, AddressListModal } from '@theme/views/address-modal/';
import LocationModal from './location-modal';
import { ROUTES } from '@theme/routes';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { useAddress } from '@theme/hooks';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { getCookie } from '@akinon/next/utils';

const LocationButton = () => {
  const { status } = useSession();
  const { t } = useLocalization();
  const { location, getLocation, setLocation } = useUserLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isClosingModal, setIsClosingModal] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const locationPopupRef = useRef(null);
  const handleClickOutside = () => setIsMenuOpen(false);
  useOnClickOutside(locationPopupRef, handleClickOutside);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: location.latitude,
    longitude: location.longitude,
    locationTitle: location.locationTitle,
    country: location.country,
    ffc: location.ffc
  });
  const [isVisible, setIsVisible] = useState(false);
  const { width } = useWindowSize();
  const { addressList } = useAddress();
  const selectedAddressPk = getCookie('selectedAddress');
  const [addressData, setAddressData] = useState(null);

  useEffect(() => {
    setAddressData(
      addressList?.find((address) => address.pk === Number(selectedAddressPk))
    );
  }, [selectedAddressPk, addressList]);

  const isMobile = width < 425;

  useEffect(() => {
    const shownLocationPopup = localStorage.getItem('shownLocationPopup');

    if (!shownLocationPopup) {
      setIsVisible(true);
      localStorage.setItem('shownLocationPopup', 'true');
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);


  useEffect(() => {
    if (location.locationTitle && isLoading) {
      setIsLoading(false);
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
        setIsMenuOpen(false);
      }, 600);
    }
  }, [location, isLoading]);

  useEffect(() => {
    setIsClosingModal(isAddressModalOpen);
  }, [isAddressModalOpen]);

  return (
    <>
      <div className="relative">
        <div
          onClick={
            status === 'authenticated' && addressList?.length > 0
              ? () => setIsAddressModalOpen(true)
              : status === 'authenticated' && addressList?.length === 0
                ? () => setIsNewAddressModalOpen(true)
                : toggleMenu
          }
          className={twMerge(
            clsx(
              'ms-1.5 flex cursor-pointer items-center justify-center gap-1.5 md:ms-3 lg:ms-0 lg:gap-2'
            ),
            isLoading && 'pointer-events-none'
          )}
        >
          <Icon name="location-pin-icon" size={24} className="text-[#04a550]" />
          <span
            className={`max-w-[${location ? 200 : 160}px] font-sans text-xs font-medium tracking-normal text-black hover:text-primary lg:text-sm lg:leading-[1.14]`}
          >
            {location
              ? status === 'authenticated' && isMobile && addressData
                ? `${addressData.township?.name?.slice(0, 10)}...`
                : (addressData?.township?.name || location.locationTitle)
              : t('common.location.locate_me')}
          </span>
        </div>

        {status !== 'authenticated' && (isMenuOpen || isVisible) && (
          <AnimatePresence>
            <motion.div
              className="absolute top-[40px] z-50 flex w-[235px] flex-col rounded-xl border bg-white p-6 pb-[22px]"
              ref={locationPopupRef}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <motion.p
                className="mb-2 truncate font-medium"
                key="locate-me"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {t('common.location.let_us_locate_you')}
              </motion.p>
              <motion.span
                className="mb-4 text-sm text-gray-620"
                key="detect-location-message"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {t('common.location.detect_location_message')}
              </motion.span>
              <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Button
                  onClick={()=>{
                    setMapModalOpen(true)
                    toggleMenu()
                  }}
                  disabled={isLoading}
                  className="mb-4 flex h-[46px] items-center justify-center"
                >
                  {isLoading ? (
                    <LoaderSpinner className="h-6 w-6" />
                  ) : isSaved ? (
                    <Icon name="check" size={22} className="mr-2" />
                  ) : (
                    <>
                      <Icon
                        name="location-pin-icon"
                        size={22}
                        className="mr-2"
                      />
                      {t('common.location.deliver_here')}
                    </>
                  )}
                </Button>
              </motion.div>
              <span
                onClick={() => {
                  setMapModalOpen(true);
                  toggleMenu();
                }}
                className="mb-2 cursor-pointer text-primary"
              >
                {t('common.location.enter_manually')}
              </span>
              <Link
                href={ROUTES.AUTH + `?callbackUrl=${ROUTES.ACCOUNT_ADDRESS}`}
                onClick={() => setIsMenuOpen(false)}
                className="cursor-pointer text-primary"
              >
                {t('common.location.add_address')}
              </Link>
            </motion.div>
          </AnimatePresence>
        )}
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsVisible(false)}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 z-40 bg-black bg-opacity-10 lg:flex"
          ></motion.div>
        )}
      </div>

      {status === 'authenticated' && isClosingModal && (
        <AddressListModal
          isAddressListModalOpen={isAddressModalOpen}
          setIsAddressListModalOpen={setIsAddressModalOpen}
          setIsNewAddressModalOpen={setIsNewAddressModalOpen}
        />
      )}

      {status === 'authenticated' && isNewAddressModalOpen && (
        <NewAddressModal
          isNewAddressModalOpen={isNewAddressModalOpen}
          setIsNewAddressModalOpen={setIsNewAddressModalOpen}
        />
      )}

      {status !== 'authenticated' && mapModalOpen && (
        <LocationModal
          mapModalOpen={mapModalOpen}
          setMapModalOpen={setMapModalOpen}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          location={location}
          setLocation={setLocation}
        />
      )}
    </>
  );
};

export default LocationButton;
