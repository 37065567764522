'use client';

import { FooterAppType } from '@theme/views/footer/types';
import { Image } from '@akinon/next/components';
import { Link } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';

type FooterAppProps = {
  data: FooterAppType;
};

export default function FooterApp(props: FooterAppProps) {
  const { data } = props;
  const { t } = useLocalization();

  return (
    <div>
      <span className="text-sm font-bold">
        {t('common.footer.download_application')}
      </span>

      <div className='flex mt-5 gap-3'>
        {data?.attributes?.footer_app_items?.map((item, i) => (
          <Link
            key={i}
            href={item?.value?.redirect_url || '#'}
            target="_blank"
            className="block h-[28px] w-[91px] md:h-[35px] md:w-[120px]"
          >
            <Image
              src={item?.kwargs?.value?.logo?.url}
              alt="App Logo"
              aspectRatio={120 / 35}
              sizes="(max-width: 768px) 100vw, (max-width: 1023px) 50vw, 33vw"
              fill
            />
          </Link>
        ))}
      </div>
    </div>
  );
}
