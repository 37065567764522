import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
  Autocomplete,
  GoogleMap,
  LoadScriptNext,
  Marker
} from '@react-google-maps/api';
import { FormModal } from '@theme/components/form-modal';
import { Button, Icon, Input } from '@theme/components';
import clsx from 'clsx';
import { useLocalization } from '@akinon/next/hooks';

const LIBRARIES: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['places'];
const GOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_MAP_API_KEY ||
  'AIzaSyARU3JY2ZLLxrjKd5sa9FK0Gk368lvxI9g';

const LocationModal = ({
  mapModalOpen,
  setMapModalOpen,
  selectedLocation,
  setSelectedLocation,
  location,
  setLocation
}) => {
  const { t, locale, currency } = useLocalization();
  const mapRef = useRef(null);
  const prevLocationRef = useRef(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [passCity, setPassCity] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [showError, setShowError] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: selectedLocation.latitude || location.latitude,
    lng: selectedLocation.longitude || location.longitude
  });
  const [townshipName, setTownshipName] = useState(null);

  const [center, setCenter] = useState({
    lat: selectedLocation.latitude || location.latitude,
    lng: selectedLocation.longitude || location.longitude
  });

  const handleMapClick = useCallback(
    async (lat, lng) => {
      await fetchAddress({ lat, lng });

      const newLocation = {
        latitude: lat,
        longitude: lng,
        locationTitle: townshipName,
        country: location.country,
        ffc: location.ffc
      };

      setSelectedLocation(newLocation);

      if (selectedLocation) {
        setPassCity(true);
        location.ffc ? setShowError(false) : setShowError(true);
      }
    },
    [townshipName, selectedLocation, location, setSelectedLocation, setLocation]
  );

  const handlePlaceSelect = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const locationGeometry = place.geometry?.location;

      if (locationGeometry) {
        const newLocation = {
          latitude: locationGeometry.lat(),
          longitude: locationGeometry.lng(),
          locationTitle: place.name,
          country:
            place.address_components?.find((component) =>
              component.types.includes('country')
            )?.long_name || '',
          ffc: location.ffc
        };

        setSelectedLocation(newLocation);
        setPassCity(true);
        setShowError(false);
        setCenter({ lat: locationGeometry.lat(), lng: locationGeometry.lng() });
        setMarkerPosition({
          lat: locationGeometry.lat(),
          lng: locationGeometry.lng()
        });
        handleMapClick(locationGeometry.lat(), locationGeometry.lng());
      } else {
        setShowError(true);
      }
    }
  }, [autocomplete, location.ffc, setSelectedLocation]);

  const saveLocation = useCallback(() => {
    if (selectedLocation && selectedLocation?.ffc) {
      setLocation({
        ...selectedLocation,
        townshipName: townshipName
      });
      setMapModalOpen(false);
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [selectedLocation, setLocation, setMapModalOpen]);

  const onLoad = useCallback((autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  }, []);

  const onPlaceChanged = useCallback(() => {
    handlePlaceSelect();
  }, [handlePlaceSelect]);

  const reset = useCallback(() => {
    setOpenPopover(false);
    setPassCity(false);
    setShowError(false);
  }, []);

  const clearSearch = useCallback(() => {
    reset();
    setSelectedLocation({
      latitude: null,
      longitude: null,
      address: '',
      locationTitle: '',
      country: '',
      ffc: ''
    });
  }, [reset, setSelectedLocation]);

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSelectedLocation((prev) => ({
        ...prev,
        address: value
      }));

      if (value === '') {
        clearSearch();
      }
    },
    [clearSearch, setSelectedLocation]
  );

  const handleCenterChanged = useCallback(() => {
    if (mapRef?.current) {
      const newCenter = mapRef?.current.getCenter();
      const newLat = newCenter?.lat();
      const newLng = newCenter?.lng();

      if (
        newLat !== prevLocationRef.current?.latitude ||
        newLng !== prevLocationRef.current?.longitude
      ) {
        setMarkerPosition({ lat: newLat, lng: newLng });
        prevLocationRef.current = { latitude: newLat, longitude: newLng };
      }
    }
  }, []);

  const fetchAddress = async (location) => {
    let data;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?loading=async&latlng=${location.lat},${location.lng}&key=${process.env.NEXT_PUBLIC_MAP_API_KEY || 'AIzaSyARU3JY2ZLLxrjKd5sa9FK0Gk368lvxI9g'}`
      );
      const mapsData = await response.json();
      data = mapsData;

      const addressComponents = data.results.find(
        (result) =>
          result.address_components && result.address_components.length > 0
      )?.address_components;

      const township = addressComponents.find((component) => {
        return (
          component.types.includes('administrative_area_level_2') ||
          component.types.includes('locality') ||
          component.types.includes('political')
        );
      });
      setTownshipName(township?.short_name);
    } catch (error) {
      console.error('Error fetching address:', error);
    }

    return data;
  };

  const checkCountry = async (lat, lng) => {
    const countryInfo = {
      lat: lat,
      lng: lng
    };

    const response = await fetchAddress(countryInfo);

    const addressComponents = response.results.find(
      (result) =>
        result.address_components && result.address_components.length > 0
    )?.address_components;
    const country = addressComponents.find((component) =>
      component.types.includes('country')
    );

    const township = addressComponents.find((component) => {
      return (
        component.types.includes('administrative_area_level_2') ||
        component.types.includes('locality') ||
        component.types.includes('political')
      );
    });
    setTownshipName(township?.short_name);
    if (!country || country.short_name.toLowerCase() !== locale.split('-')[1]) {
      return false;
    } else {
      return true;
    }
  };

  const onMapLoad = async (map) => {
    mapRef.current = map;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          if (
            await checkCountry(
              position.coords.latitude,
              position.coords.longitude
            )
          ) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            const ffcInfo = {
              latitude: lat,
              longitude: lng,
              currency: currency,
              townshipName: townshipName
            };

            const { ffc } = await setLocation(ffcInfo);

            if (!ffc) {
              setShowError(true);
            } else {
              setShowError(false);
              const newLocation = { lat, lng };
              setSelectedLocation(newLocation);
              setCenter(newLocation);
              setMarkerPosition(newLocation);
              onDragEnd();
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const onDragEnd = useCallback(async () => {
    if (mapRef?.current) {
      const newCenter = mapRef?.current?.getCenter();
      const newLat = newCenter?.lat();
      const newLng = newCenter?.lng();

      await fetchAddress({ lat: newLat, lng: newLng });

      const newLocation = {
        latitude: newLat,
        longitude: newLng,
        locationTitle: townshipName,
        country: location.country,
        ffc: location.ffc
      };

      setSelectedLocation(newLocation);

      if (selectedLocation) {
        setPassCity(true);
        location.ffc ? setShowError(false) : setShowError(true);
      }

      prevLocationRef.current = { latitude: newLat, longitude: newLng };
    }
  }, []);

  useEffect(() => {
    if (
      selectedLocation.latitude !== center.lat ||
      selectedLocation.longitude !== center.lng
    ) {
      setCenter({
        lat: selectedLocation.latitude || location.latitude,
        lng: selectedLocation.longitude || location.longitude
      });
      setMarkerPosition({
        lat: selectedLocation.latitude || location.latitude,
        lng: selectedLocation.longitude || location.longitude
      });
    }
  }, []);

  const mapOptions = useMemo(
    () => ({
      disableDefaultUI: true,
      zoomControl: true
    }),
    []
  );

  return (
    <FormModal
      portalId="select-address-modal"
      title={t('common.location.select_location')}
      open={mapModalOpen}
      setOpen={setMapModalOpen}
      className="z-[99999] max-h-[90vh] w-full overflow-y-auto sm:w-[28rem]"
      childrenClassName="flex flex-col"
    >
      <div className={clsx('relative mb-[22px]', 'pl-[34px] pr-[30px]')}>
        <label className="absolute -top-2 left-[50px] z-20 bg-white px-1 text-xs font-normal text-[#393A3A]">
          {t('account.address_book.form.location.title')}
        </label>
        <Autocomplete
          onLoad={onLoad}
          restrictions={{ country: locale.split('-')[1] }}
          onPlaceChanged={onPlaceChanged}
        >
          <Input
            value={selectedLocation?.address}
            onChange={handleInputChange}
            placeholder={t('account.address_book.form.location.placeholder')}
            className="h-[54px] w-full rounded-lg border-[#C1C2C2] pe-[70px] text-base placeholder:text-sm placeholder:text-[#C1C2C2]"
            data-testid="basket-voucher-input"
          />
        </Autocomplete>
        <Button
          onClick={clearSearch}
          className="absolute bottom-0 end-8 top-0 h-full border-none bg-transparent text-black hover:bg-transparent hover:text-black"
        >
          {t('common.location.clear')}
        </Button>
      </div>
      <LoadScriptNext
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        libraries={LIBRARIES}
        onLoad={() => setGoogleMapsLoaded(true)}
      >
        {googleMapsLoaded && (
          <GoogleMap
            mapContainerClassName="ml-[34px] h-[408px] mr-[30px] mb-28 lg:mb-0 rounded-xl"
            center={center}
            zoom={12}
            options={mapOptions}
            onClick={(e) => handleMapClick(e.latLng.lat(), e.latLng.lng())}
            onLoad={onMapLoad}
            onCenterChanged={handleCenterChanged}
            onDragEnd={onDragEnd}
          >
            <Marker
              icon={{
                fillColor: '#4285F4',
                fillOpacity: 1,
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: 'rgb(255,255,255)',
                strokeWeight: 2
              }}
              position={markerPosition}
            />
          </GoogleMap>
        )}
      </LoadScriptNext>
      <div
        className="absolute bottom-0 z-50 flex w-full items-center justify-between border-t bg-gray-25 py-3 pl-[34px] pr-[26px]"
        onClick={() => setOpenPopover(true)}
      >
        <div className="flex items-center gap-1">
          {showError && (
            <>
              <Icon name="info" className="text-[#bb2027]" size={14}></Icon>
              <p className="text-xs text-[#bb2027]">
                {t('account.address_book.modal.invalid_address')}
              </p>
            </>
          )}
          {!passCity && (
            <>
              <Icon name="info" className="text-primary" size={14}></Icon>
              <p className="text-xs text-primary">
                {t('account.address_book.modal.map_info')}
              </p>
            </>
          )}
        </div>

        <div
          onMouseEnter={() => !passCity && setOpenPopover(true)}
          onMouseLeave={() => !passCity && setOpenPopover(false)}
        >
          <Button
            disabled={!passCity || showError}
            onClick={saveLocation}
            className={clsx(
              'h-[49px] w-[98px] rounded-[136px] bg-primary text-base font-normal',
              (!passCity || showError) &&
                'opacity-40 hover:bg-primary hover:text-white'
            )}
          >
            {t('common.location.save')}
          </Button>
        </div>
      </div>
    </FormModal>
  );
};

export default LocationModal;
