'use client';

import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  basketApi,
  useLazyGetBasketDetailQuery,
  useUpdateQuantityMutation
} from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { BasketItem } from '@akinon/next/types';
import { Price, Button, Icon, LoaderSpinner, Link } from '@theme/components';
import { ROUTES } from '@theme/routes';
import {
  useCommonProductAttributes,
  useLocalization
} from '@akinon/next/hooks';
import { Image } from '@akinon/next/components/image';
import { pushRemoveFromCart } from '@theme/utils/gtm';
import { getCookie } from '@akinon/next/utils';
import { swanRemoveFromCart } from '@theme/utils/swan';

interface MiniBasketItemProps {
  basketItem: BasketItem;
  highlightedItem: number;
  miniBasketListRef: MutableRefObject<HTMLUListElement>;
  theme: string;
}

function MiniBasketItem(props: MiniBasketItemProps) {
  const { basketItem, highlightedItem, miniBasketListRef, theme } = props;
  const dispatch = useAppDispatch();
  const [updateQuantityMutation] = useUpdateQuantityMutation();
  const commonProductAttributes = useCommonProductAttributes({
    attributes: basketItem.product.attributes_kwargs
  });
  const { t } = useLocalization();

  const isHighlighted = useMemo(() => {
    return highlightedItem === basketItem.product.pk;
  }, [highlightedItem, basketItem.product.pk]);

  useEffect(() => {
    const miniBasketList = miniBasketListRef.current;

    if (highlightedItem === basketItem.product.pk) {
      if (miniBasketList.scrollTop > 0) {
        miniBasketList.scrollTop = 0;
      }
    }
  }, [highlightedItem, basketItem.product.pk, miniBasketListRef]);

  const removeItem = () => {
    const bc = new BroadcastChannel('preorder_channel');
    bc.postMessage('update_preorder');
    const requestParams: any = {
      product: basketItem.product.pk,
      quantity: 0,
      attributes: basketItem.attributes,
      namespace: theme
    };

    updateQuantityMutation(requestParams)
      .unwrap()
      .then((data) => {
        dispatch(
          basketApi.util.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data.basket);
            }
          )
        );

        pushRemoveFromCart(basketItem?.product);
        swanRemoveFromCart(basketItem?.product);
      });
  };

  return (
    <li
      style={{ order: isHighlighted ? '-1' : '0' }}
      className={clsx(
        'flex w-full items-center justify-between py-[0.3125rem]'
      )}
    >
      <div className="flex w-full items-center justify-start gap-1.5">
        <Link
          href={basketItem?.product?.absolute_url}
          className={clsx(
            'flex h-[2.9375rem] w-[3.125rem] shrink-0 items-center justify-center rounded-lg bg-gray-25 px-1 py-1.5 transition-all duration-300',
            isHighlighted ? 'h-40 w-24' : 'h-20 w-12'
          )}
        >
          <Image
            src={basketItem?.product?.productimage_set?.[0]?.image || '/akn-noimage.jpg'}
            alt={basketItem?.product?.name}
            className="transition-all duration-300"
            aspectRatio={isHighlighted ? 96 / 160 : 48 / 47}
            sizes={isHighlighted ? '96px' : '48px'}
            fill
          />
        </Link>
        <div className="flex h-fit min-h-[3.4375rem] w-[13.4375rem] flex-1 flex-col gap-1 py-1.5">
          <Link
            href={basketItem.product.absolute_url}
            className="line-clamp-2 text-sm font-semibold text-[#404553]"
            data-testid="mini-basket-item-name"
          >
            {basketItem?.product?.name}
          </Link>
          {commonProductAttributes?.map((attribute, index) => (
            <span
              key={index}
              data-testid={`mini-basket-item-${attribute?.name.toLowerCase()}`}
              className="text-sm font-semibold text-[#404553]"
            >
              {attribute?.name}: {attribute?.value}
            </span>
          ))}
          <footer className="flex items-center gap-2 py-0.5">
            {parseFloat(basketItem?.retail_price) >
              parseFloat(basketItem?.price) && (
              <Price
                value={
                  parseFloat(basketItem?.retail_price) * basketItem?.quantity
                }
                className="line-through"
              />
            )}
            <Price
              value={basketItem?.total_amount}
              className="text-sm font-medium leading-4 text-[#404553]"
            />
            <span className="text-xs font-normal leading-5 text-gray-620">
              {basketItem?.quantity} {t('basket.mini_basket.qnty')}
            </span>
          </footer>
        </div>
        <Button
          appearance="ghost"
          className={clsx(
            'h-auto border-none p-1 text-xs font-semibold outline-none transition-all duration-300 hover:bg-transparent',
            isHighlighted ? 'invisible' : 'visible'
          )}
          onClick={removeItem}
        >
          <Icon name="trash" size={16} className="text-[#EC4149]" />
        </Button>
      </div>
    </li>
  );
}

export default function MiniBasket() {
  const theme = getCookie('theme');
  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );

  const dispatch = useAppDispatch();
  const [getBasketDetail, { data: basket, isLoading, isSuccess }] =
    useLazyGetBasketDetailQuery();
  const { t } = useLocalization();
  const { highlightedItem } = useAppSelector((state) => state.root.miniBasket);
  const [highlightedItemPk, setHighlightedItemPk] = useState(0);
  const [sortedBasket, setSortedBasket] = useState([]);
  const miniBasketList = useRef();

  const filteredCartItems = basket?.basketitem_set.filter(
    (item) => item.product.attributes.misc_product_hide !== 'hide'
  );
  const hiddenItem = basket?.basketitem_set.find(
    (item) => item.product.attributes.misc_product_hide === 'hide'
  );

  const totalQuantity = useMemo(
    () =>
      hiddenItem ? basket?.total_quantity - 1 : basket?.total_quantity ?? 0,
    [basket]
  );

  useEffect(() => {
    if (theme) {
      getBasketDetail({ namespace: theme });
    }
  }, [theme, highlightedItem]);

  useEffect(() => {
    if (highlightedItem > 0) {
      setHighlightedItemPk(highlightedItem);
    }
  }, [highlightedItem]);

  useEffect(() => {
    if (isSuccess) {
      if (highlightedItemPk > 0) {
        setSortedBasket(
          filteredCartItems.slice().sort((a, b) => {
            if (a.product.pk === highlightedItemPk) {
              return -1;
            } else if (b.product.pk === highlightedItemPk) {
              return 1;
            } else {
              return a.product.pk - b.product.pk;
            }
          })
        );
      } else {
        setSortedBasket(filteredCartItems);
      }
    }
  }, [isSuccess, highlightedItem, basket, highlightedItemPk]);

  return (
    <>
      <div
        className={clsx(
          miniBasketOpen
            ? 'visible opacity-100 lg:invisible'
            : 'invisible opacity-0',
          'fixed left-0 top-0 z-50 h-screen w-screen bg-black bg-opacity-80 transition-all duration-300'
        )}
        onClick={() => {
          dispatch(closeMiniBasket());
        }}
      />
      <div
        className={clsx(
          miniBasketOpen
            ? 'visible z-50 flex flex-col opacity-100 lg:translate-y-[calc(100%)]'
            : 'invisible translate-x-full opacity-0 lg:translate-x-0 lg:translate-y-[calc(100%+16px)]',
          'fixed bottom-0 end-0 z-50 h-[25.3125rem] w-[21.1875rem] rounded-lg border border-black border-opacity-5 bg-white transition-all duration-300 lg:absolute lg:-bottom-1'
        )}
        style={{ boxShadow: '0px 4px 15px 0px #0000000D' }}
      >
        <header className="mb-2.5 flex items-center gap-2.5 border-b border-gray-380 bg-gray-25 px-[1.625rem] py-2">
          <h3 className="text-base font-bold leading-[1.375rem] text-black">
            {t('basket.mini_basket.my_bag')}
          </h3>
          <span className="text-sm font-medium leading-[1.1875rem] text-gray-620">
            {totalQuantity} {t('basket.mini_basket.items')}
          </span>
        </header>
        {isLoading && <LoaderSpinner />}
        {isSuccess && (
          <ul
            ref={miniBasketList}
            className="flex flex-col gap-[1.375rem] overflow-y-auto px-3 lg:max-h-64"
          >
            {sortedBasket?.map((basketItem) => (
              <MiniBasketItem
                key={basketItem?.id}
                basketItem={basketItem}
                highlightedItem={highlightedItem}
                miniBasketListRef={miniBasketList}
                theme={theme}
              />
            ))}
          </ul>
        )}
        <footer
          className="absolute bottom-0 flex w-full items-center justify-between bg-gray-25 px-[1.625rem] py-[0.8125rem]"
          style={{ boxShadow: '0px -9px 25px 0px #0000000A' }}
        >
          <Price
            value={basket?.total_amount}
            className="whitespace-nowrap text-sm font-bold leading-4 text-primary"
          />
          <Link
            onClick={() => {
              dispatch(closeMiniBasket());
            }}
            href={ROUTES.BASKET}
            data-testid="mini-basket-view-bag"
            className="flex h-[2.6rem] w-fit min-w-[8.75rem] items-center justify-center whitespace-nowrap rounded-full bg-primary px-[1.2rem] py-[0.9375rem] text-[0.9rem] font-normal leading-[1.125rem] text-white transition-all"
          >
            {t('basket.mini_basket.go_to_cart')}
          </Link>
        </footer>
      </div>
    </>
  );
}
