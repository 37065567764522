'use client';

import { useMemo } from 'react';
import { useAutocompleteQuery } from '@akinon/next/data/client/misc';
import { ROUTES } from '@theme/routes';
import { LoaderSpinner, Price, Link } from '@theme/components';
import { useDebounce, useLocalization } from '@akinon/next/hooks';
import { Image } from '@akinon/next/components/image';
interface ResultsProps {
  searchText: string;
}

const MINIMUM_SEARCH_LENGTH = 3;

export default function Results(props: ResultsProps) {
  const { searchText } = props;
  const { t } = useLocalization();
  const debouncedSearchText = useDebounce(searchText ?? '', 400);
  const { currentData, isFetching, isLoading } = useAutocompleteQuery(
    debouncedSearchText,
    {
      refetchOnMountOrArgChange: true,
      skip: debouncedSearchText?.length < MINIMUM_SEARCH_LENGTH
    }
  );

  const categories = useMemo(
    () =>
      currentData?.groups.find((group) => group?.suggestion_type === 'Category')
        ?.entries ?? [],
    [currentData]
  );

  const products = useMemo(
    () =>
      currentData?.groups.find((group) => group?.suggestion_type === 'Product')
        ?.entries ?? [],
    [currentData]
  );

  if (
    debouncedSearchText.length < MINIMUM_SEARCH_LENGTH ||
    searchText !== debouncedSearchText
  ) {
    return null;
  }

  if (isLoading || isFetching) {
    return <LoaderSpinner />;
  }

  if (!categories?.length && !products?.length) {
    return <p className="text-center">{t('common.search.not_found')}</p>;
  }

  return (
    <div id='autocomplete-results' className="flex w-full flex-wrap gap-4 md:gap-0 pt-4">
      {categories.length > 0 && (
        <div className="flex w-44 flex-col">
          <h6 className="mb-3 font-semibold">
            {t('common.search.categories')}
          </h6>
          <ul className="flex flex-col gap-2">
            {categories.map((category, index) => (
              <li key={index} className="text-sm">
                <Link href={category.url}>{category.label}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex flex-1 flex-col gap-4">
        <h6 className="font-semibold">
          {t('common.search.products_for')}{' '}
          <span className="uppercase text-secondary">
            {debouncedSearchText}
          </span>
        </h6>
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-4">
          {products.map((product, index) => (
            <Link href={product?.url} key={index} className="flex flex-col">
              <div className="relative aspect-[315/448]">
                {product.extra.image ? (
                  <Image
                    src={product.extra.image}
                    alt={product?.label}
                    aspectRatio={274.5 / 390.39}
                    fill
                    sizes="(min-width: 320px) 164px,
                     (min-width: 640px) 50vw,
                     (min-width: 1160px) 315px"
                  />
                ) : (
                  <Image
                    className="h-full object-cover"
                    src="/akn-noimage.jpg"
                    alt={product?.label}
                    aspectRatio={274.5 / 390.39}
                    fill
                    sizes="100vw"
                  />
                )}
              </div>
              <span className="mt-2 line-clamp-2 overflow-hidden text-sm">
                {product?.label}
              </span>
              <Price
                value={product?.extra?.price}
                className="text-sm font-semibold"
              />
            </Link>
          ))}
        </div>
        <Link
          href={`${ROUTES.LIST}/?search_text=${debouncedSearchText}`}
          data-testid="search-view-all"
          className="w-full border border-primary px-10 py-3 text-center text-xs font-semibold transition-all hover:bg-primary hover:text-primary-foreground"
        >
          {t('common.search.view_all')} {debouncedSearchText.toUpperCase()}
        </Link>
      </div>
    </div>
  );
}
