import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/node_modules/@akinon/next/components/image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@akinon/next/components/mobile-app-toggler.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CountrySelect"] */ "/app/src/components/country-select.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ActionMenu"] */ "/app/src/views/header/action-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/location-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/mobile-hamburger-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/mobile-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/mode-switcher-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/navigation/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/header/search/mobile-search-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserMenu"] */ "/app/src/views/header/user-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/footer/footer-app.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/footer/footer-contact.tsx");
