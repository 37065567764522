'use client';

import { useLocalization, useRouter } from '@akinon/next/hooks';
import { Icon } from '@theme/components';
import { ROUTES } from '@theme/routes';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Search from '.';
import { usePathname, useSearchParams } from 'next/navigation';
import { useAnimate } from 'framer-motion';
import { swanSearch } from '@theme/utils/swan';
import fetchSearchRedirectionResult from './samanlik-client';

export default function MobileSearchBar({searchItems}) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pathChanged, setPageChanged] = useState({
    changed: false,
    pathname: pathname
  });

  const { t, locale } = useLocalization();
  const [scope, animate] = useAnimate();

  const placeholders = searchItems?.attributes?.placeholder_items;
  const trendings = searchItems?.attributes?.trend_items;

  useEffect(() => {
    const searchPage = searchParams.get('search_text');

    if (pathname !== pathChanged.pathname) {
      setPageChanged({ changed: true, pathname: pathname });

      if (!searchPage && pathChanged) {
        setSearchText('');
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (placeholders && placeholders?.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === placeholders?.length - 1 ? 0 : prevIndex + 1
        );
      }, 2500);

      return () => clearInterval(interval);
    }
  }, [placeholders]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchText) {
      handleSubmit();
    }
  };

  const handleSearchTextCleaned = async () => {
    const inputElement = document.getElementById('header-nav-search');
    inputElement.blur();
  };

  const handleSubmit = () => {
    do_search(searchText);
  };

  const do_search = async (searchQuery: string) => {
    const url = `${ROUTES.LIST}/?search_text=${searchQuery}`;
    if (searchQuery) {
      const response = fetchSearchRedirectionResult(searchQuery);
      response.then((data) => {
        if (data.url_path !== undefined) {
          router.push(data.url_path);
        } else {
          router.push(url);
        }
      });
      response.catch((error) => {
        console.error('error', error);
        router.push(url);
      });
      setSearchOpen(false);
      updateSearchHistory(searchQuery);
      swanSearch(searchQuery);
    }
  };

  const updateSearchHistory = async (text) => {
    const searchHistory =
      JSON.parse(localStorage.getItem('searchHistory')) || [];
    localStorage.setItem(
      'searchHistory',
      JSON.stringify([text, ...searchHistory])
    );

    await handleSearchTextCleaned();
  };

  const handleClickOutside = (event) => {
    const input = document.getElementById('mobile-nav-search');
    const modal = document.getElementById('header-search-modal');

    if (modal && input) {
      if (!modal.contains(event?.target) && !input.contains(event?.target)) {
        setSearchOpen(false);
      }
    }
  };

  useEffect(() => {
    if (searchOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('click', handleClickOutside);
      const headerBand = document.getElementById('header-band')?.offsetHeight;
      /* animate(
        scope.current,
        { y: headerBand - window.scrollY > 120 ? -120 : - (headerBand - window.scrollY)},
        { type: 'tween', ease: 'easeInOut', duration: 0.2 }
      ); */
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.body.style.overflow = 'auto';
      /* animate(
        scope.current,
        { y: 0 },
        { type: 'tween', ease: 'easeInOut', duration: 0.2 }
      ); */
    };
  }, [searchOpen]);

  useEffect(() => {
    if (pathChanged.changed) {
      setSearchOpen(false);
    }
  }, [pathChanged]);

  return (
    <>
      <Icon name="search-white" size={20} onClick={() => setSearchOpen(true)} />

      <div
        ref={scope}
        className={clsx(
          'absolute left-0 top-0 z-30 flex h-full w-full items-center lg:hidden',
          searchOpen == true ? 'block' : 'hidden',
        )}
      >
        <div className="relative w-full h-full z-50 bg-white">
          <div className="absolute left-7 top-4 z-50 flex w-[calc(100vw_-_60px)] items-center justify-between lg:hidden">
            <div className="text-2xl">{t('category.filters.search')}</div>
            <Icon name="close" size={16} onClick={() => setSearchOpen(false)} />
          </div>
          <div
            className={twMerge(
              'absolute z-50 mx-[1.5625rem] mt-16 h-[3.25rem] w-[calc(100vw_-_50px)] transition-colors',
              'overflow-hidden rounded-[6.25rem] border border-primary/10 bg-white',
              'mb-2 lg:h-[52px]',
              'text-sm leading-[1.43] text-[#999999]',
              clsx({
                'border-2 border-primary border-opacity-100': searchOpen
              })
            )}
          >
            <input
              onChange={(e) => setSearchText(e?.target?.value)}
              onKeyDown={handleKeyDown}
              className={clsx(
                'absolute inset-0 z-50 py-1.5 pe-12 ps-[22px] outline-none',
                locale === 'ar-qa' && '!pe-[22px] !ps-14'
              )}
              id="mobile-nav-search"
              data-testid="mobile-nav-search"
              value={searchText || ''}
            />

            {!searchText && (
              <span
                className={clsx(
                  'pointer-events-none absolute inset-0 z-50 flex items-center whitespace-nowrap py-1.5 pe-12 ps-[22px] text-sm leading-[1.43] text-[#999999]',
                  locale === 'ar-qa' && '!pe-[22px] !ps-14'
                )}
              >
                {t('common.navbar.search')}{' '}
                {placeholders ? (
                  <span
                    key={currentIndex}
                    className="z-50 inline-block transform-gpu animate-fade-in-up transition-transform"
                  >
                    "{placeholders[currentIndex]?.value}"
                  </span>
                ) : (
                  <span
                    key={currentIndex}
                    className="z-50 inline-block transform-gpu animate-fade-in-up transition-transform"
                  >
                    "{t('common.navbar.default_search_placeholder')}"
                  </span>
                )}
              </span>
            )}

            <div
              onClick={() => handleSubmit()}
              className="absolute end-1.5 top-1/2 z-50 flex size-10 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-primary"
            >
              <Icon name="search-white" className="text-white" size={18} />
            </div>
          </div>
        </div>

        {/* <button
        onClick={() => dispatch(openSearch())}
        className="flex max-h-[52px] w-full items-center justify-between rounded-[120px] border border-primary border-opacity-10 py-1.5 pl-[22px] pr-1.5"
        data-testid="mobile-nav-search"
      >
        <span className="text-sm leading-[1.43] text-[#999999]">
          {t('common.navbar.search')}
        </span>
        <div className="flex size-10 items-center justify-center rounded-full bg-primary">
          <Icon name="search-white" className="text-white" size={18} />
        </div>
      </button> */}

        {searchOpen && (
          <Search
            setSearchOpen={setSearchOpen}
            data={trendings}
            searchText={searchText}
            setSearchText={setSearchText}
            doSearch={do_search}
          />
        )}
      </div>
    </>
  );
}
